import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';

import { ShippingContainers_packing_slip_reportComponent } from './ShippingContainers.packing_slip_report.component';
import { ShippingContainers_shipping_label_reportComponent } from './ShippingContainers.shipping_label_report.component';
import { ShippingContainers_container_type_dd_singleComponent } from './ShippingContainers.container_type_dd_single.component';
import { ShippingContainers_order_types_singleComponent } from './ShippingContainers.order_types_single.component';
import { ShippingContainers_shipment_statuses_dd_singleComponent } from './ShippingContainers.shipment_statuses_dd_single.component';
import { ShippingContainers_shipping_container_dates_dd_singleComponent } from './ShippingContainers.shipping_container_dates_dd_single.component';
import { ShippingContainers_task_dates_dd_singleComponent } from './ShippingContainers.task_dates_dd_single.component';
import { ShippingContainers_container_type_dd_multiComponent } from './ShippingContainers.container_type_dd_multi.component';
import { ShippingContainers_order_types_multiComponent } from './ShippingContainers.order_types_multi.component';
import { ShippingContainers_shipment_statuses_dd_multiComponent } from './ShippingContainers.shipment_statuses_dd_multi.component';
import { ShippingContainers_shipping_container_dates_dd_multiComponent } from './ShippingContainers.shipping_container_dates_dd_multi.component';
import { ShippingContainers_task_dates_dd_multiComponent } from './ShippingContainers.task_dates_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class ShippingContainers_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);

  }

  public ShippingContainers: ShippingContainers_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openpacking_slip_report(inParams:{ shippingContainerId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'packing_slip_report',
        referenceName: 'ShippingContainers_packing_slip_report',
        component: ShippingContainers_packing_slip_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpacking_slip_reportDialog(
    inParams:{ shippingContainerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {

    let dialogSize;

    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      ShippingContainers_packing_slip_reportComponent,
      'packing_slip_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openshipping_label_report(inParams:{ shippingContainerId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'shipping_label_report',
        referenceName: 'ShippingContainers_shipping_label_report',
        component: ShippingContainers_shipping_label_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openshipping_label_reportDialog(
    inParams:{ shippingContainerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {

    let dialogSize;

    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      ShippingContainers_shipping_label_reportComponent,
      'shipping_label_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'ShippingContainers_packing_slip_report') {
      const title = 'packing_slip_report';
      const component = ShippingContainers_packing_slip_reportComponent;
      const inParams:{ shippingContainerId: number } = { shippingContainerId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ShippingContainers_shipping_label_report') {
      const title = 'shipping_label_report';
      const component = ShippingContainers_shipping_label_reportComponent;
      const inParams:{ shippingContainerId: number } = { shippingContainerId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ShippingContainers_container_type_dd_single') {
      const title = 'Container type dropdown';
      const component = ShippingContainers_container_type_dd_singleComponent;
      const inParams:{ ownerId?: number, projectId?: number, materialId?: number } = { ownerId: null, projectId: null, materialId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ShippingContainers_order_types_single') {
      const title = 'Order Types';
      const component = ShippingContainers_order_types_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ShippingContainers_shipment_statuses_dd_single') {
      const title = 'shipment_statuses_dd';
      const component = ShippingContainers_shipment_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ShippingContainers_shipping_container_dates_dd_single') {
      const title = 'shipping_container_dates_dd';
      const component = ShippingContainers_shipping_container_dates_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ShippingContainers_task_dates_dd_single') {
      const title = 'task_dates_dd';
      const component = ShippingContainers_task_dates_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ShippingContainers_container_type_dd_multi') {
      const title = 'Container type dropdown';
      const component = ShippingContainers_container_type_dd_multiComponent;
      const inParams:{ ownerId?: number, projectId?: number, materialId?: number } = { ownerId: null, projectId: null, materialId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ShippingContainers_order_types_multi') {
      const title = 'Order Types';
      const component = ShippingContainers_order_types_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ShippingContainers_shipment_statuses_dd_multi') {
      const title = 'shipment_statuses_dd';
      const component = ShippingContainers_shipment_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ShippingContainers_shipping_container_dates_dd_multi') {
      const title = 'shipping_container_dates_dd';
      const component = ShippingContainers_shipping_container_dates_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ShippingContainers_task_dates_dd_multi') {
      const title = 'task_dates_dd';
      const component = ShippingContainers_task_dates_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
