import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SalesOrders_ds_sales_order_lines_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number, fullTextSearch?: string }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, GrossWeight?: number, LicensePlateId?: number, LotId?: number, MaterialId?: number, NetWeight?: number, Notes?: string, PackagedAmount?: number, PackagedId?: number, ParentLineNumber?: number, SerialNumberId?: number, StatusId?: number, VendorLotId?: number, WeightUomId?: number, Material?: { LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string }, InventoryMeasurementUnit?: { ShortName?: string }, Status?: { Name?: string }, WeightUom?: { Name?: string, Short_name?: string }, ShipmentLines?: { ActualPackagedAmount?: number }[], LicensePlate?: { LookupCode?: string, Location?: { Name?: string } }, SerialNumber?: { LookupCode?: string, LicensePlate?: { LookupCode?: string, Location?: { Name?: string } } }, total_picked?: { TotalCount?: number, ExpectedCount?: number, ShipmentLine?: { OrderId?: number, OrderLineNumber?: number } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_sales_order_lines_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, fullTextSearch?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, GrossWeight?: number, LicensePlateId?: number, LotId?: number, MaterialId?: number, NetWeight?: number, Notes?: string, PackagedAmount?: number, PackagedId?: number, ParentLineNumber?: number, SerialNumberId?: number, StatusId?: number, VendorLotId?: number, WeightUomId?: number, Material?: { LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string }, InventoryMeasurementUnit?: { ShortName?: string }, Status?: { Name?: string }, WeightUom?: { Name?: string, Short_name?: string }, ShipmentLines?: { ActualPackagedAmount?: number }[], LicensePlate?: { LookupCode?: string, Location?: { Name?: string } }, SerialNumber?: { LookupCode?: string, LicensePlate?: { LookupCode?: string, Location?: { Name?: string } } }, total_picked?: { TotalCount?: number, ExpectedCount?: number, ShipmentLine?: { OrderId?: number, OrderLineNumber?: number } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_sales_order_lines_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, fullTextSearch?: string, $keys: { OrderId?: number, LineNumber?: number }[] }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, GrossWeight?: number, LicensePlateId?: number, LotId?: number, MaterialId?: number, NetWeight?: number, Notes?: string, PackagedAmount?: number, PackagedId?: number, ParentLineNumber?: number, SerialNumberId?: number, StatusId?: number, VendorLotId?: number, WeightUomId?: number, Material?: { LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string }, InventoryMeasurementUnit?: { ShortName?: string }, Status?: { Name?: string }, WeightUom?: { Name?: string, Short_name?: string }, ShipmentLines?: { ActualPackagedAmount?: number }[], LicensePlate?: { LookupCode?: string, Location?: { Name?: string } }, SerialNumber?: { LookupCode?: string, LicensePlate?: { LookupCode?: string, Location?: { Name?: string } } }, total_picked?: { TotalCount?: number, ExpectedCount?: number, ShipmentLine?: { OrderId?: number, OrderLineNumber?: number } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_sales_order_lines_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

