import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';

import { InventoryCounts_inventorycounts_library_homeComponent } from './InventoryCounts.inventorycounts_library_home.component';
import { InventoryCounts_count_task_discrepancy_action_dd_singleComponent } from './InventoryCounts.count_task_discrepancy_action_dd_single.component';
import { InventoryCounts_count_task_statuses_dd_singleComponent } from './InventoryCounts.count_task_statuses_dd_single.component';
import { InventoryCounts_inventory_count_statuses_dd_singleComponent } from './InventoryCounts.inventory_count_statuses_dd_single.component';
import { InventoryCounts_material_filter_options_dd_singleComponent } from './InventoryCounts.material_filter_options_dd_single.component';
import { InventoryCounts_material_statuses_dd_singleComponent } from './InventoryCounts.material_statuses_dd_single.component';
import { InventoryCounts_projects_dd_singleComponent } from './InventoryCounts.projects_dd_single.component';
import { InventoryCounts_storage_categories_dd_singleComponent } from './InventoryCounts.storage_categories_dd_single.component';
import { InventoryCounts_storage_category_rules_dd_singleComponent } from './InventoryCounts.storage_category_rules_dd_single.component';
import { InventoryCounts_warehouses_dd_singleComponent } from './InventoryCounts.warehouses_dd_single.component';
import { InventoryCounts_zones_dd_singleComponent } from './InventoryCounts.zones_dd_single.component';
import { InventoryCounts_count_task_discrepancy_action_dd_multiComponent } from './InventoryCounts.count_task_discrepancy_action_dd_multi.component';
import { InventoryCounts_count_task_statuses_dd_multiComponent } from './InventoryCounts.count_task_statuses_dd_multi.component';
import { InventoryCounts_inventory_count_statuses_dd_multiComponent } from './InventoryCounts.inventory_count_statuses_dd_multi.component';
import { InventoryCounts_material_filter_options_dd_multiComponent } from './InventoryCounts.material_filter_options_dd_multi.component';
import { InventoryCounts_material_statuses_dd_multiComponent } from './InventoryCounts.material_statuses_dd_multi.component';
import { InventoryCounts_projects_dd_multiComponent } from './InventoryCounts.projects_dd_multi.component';
import { InventoryCounts_storage_categories_dd_multiComponent } from './InventoryCounts.storage_categories_dd_multi.component';
import { InventoryCounts_storage_category_rules_dd_multiComponent } from './InventoryCounts.storage_category_rules_dd_multi.component';
import { InventoryCounts_warehouses_dd_multiComponent } from './InventoryCounts.warehouses_dd_multi.component';
import { InventoryCounts_zones_dd_multiComponent } from './InventoryCounts.zones_dd_multi.component';
import { InventoryCounts_count_accuracy_widgetComponent } from './InventoryCounts.count_accuracy_widget.component';
import { InventoryCounts_count_tasks_progress_widgetComponent } from './InventoryCounts.count_tasks_progress_widget.component';
import { InventoryCounts_inventory_counts_in_progress_widgetComponent } from './InventoryCounts.inventory_counts_in_progress_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class InventoryCounts_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);

  }

  public InventoryCounts: InventoryCounts_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openinventorycounts_library_home(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'InventoryCounts_inventorycounts_library_home',
        component: InventoryCounts_inventorycounts_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openinventorycounts_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {

    let dialogSize;

    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      InventoryCounts_inventorycounts_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'InventoryCounts_inventorycounts_library_home') {
      const title = 'Home';
      const component = InventoryCounts_inventorycounts_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_count_task_discrepancy_action_dd_single') {
      const title = 'count_task_discrepancy_action_dd';
      const component = InventoryCounts_count_task_discrepancy_action_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_count_task_statuses_dd_single') {
      const title = 'count_task_statuses_dd';
      const component = InventoryCounts_count_task_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_inventory_count_statuses_dd_single') {
      const title = 'inventory_count_statuses_dd';
      const component = InventoryCounts_inventory_count_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_material_filter_options_dd_single') {
      const title = 'material_filter_options_dd';
      const component = InventoryCounts_material_filter_options_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_material_statuses_dd_single') {
      const title = 'material_statuses_dd';
      const component = InventoryCounts_material_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_projects_dd_single') {
      const title = 'projects_dd';
      const component = InventoryCounts_projects_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_storage_categories_dd_single') {
      const title = 'storage_categories_dd';
      const component = InventoryCounts_storage_categories_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_storage_category_rules_dd_single') {
      const title = 'storage_category_rules_dd';
      const component = InventoryCounts_storage_category_rules_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_warehouses_dd_single') {
      const title = 'warehouses_dd';
      const component = InventoryCounts_warehouses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_zones_dd_single') {
      const title = 'zones_dd';
      const component = InventoryCounts_zones_dd_singleComponent;
      const inParams:{ warehouseId: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_count_task_discrepancy_action_dd_multi') {
      const title = 'count_task_discrepancy_action_dd';
      const component = InventoryCounts_count_task_discrepancy_action_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_count_task_statuses_dd_multi') {
      const title = 'count_task_statuses_dd';
      const component = InventoryCounts_count_task_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_inventory_count_statuses_dd_multi') {
      const title = 'inventory_count_statuses_dd';
      const component = InventoryCounts_inventory_count_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_material_filter_options_dd_multi') {
      const title = 'material_filter_options_dd';
      const component = InventoryCounts_material_filter_options_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_material_statuses_dd_multi') {
      const title = 'material_statuses_dd';
      const component = InventoryCounts_material_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_projects_dd_multi') {
      const title = 'projects_dd';
      const component = InventoryCounts_projects_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_storage_categories_dd_multi') {
      const title = 'storage_categories_dd';
      const component = InventoryCounts_storage_categories_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_storage_category_rules_dd_multi') {
      const title = 'storage_category_rules_dd';
      const component = InventoryCounts_storage_category_rules_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_warehouses_dd_multi') {
      const title = 'warehouses_dd';
      const component = InventoryCounts_warehouses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_zones_dd_multi') {
      const title = 'zones_dd';
      const component = InventoryCounts_zones_dd_multiComponent;
      const inParams:{ warehouseId: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_count_accuracy_widget') {
      const title = 'Accuracy level';
      const component = InventoryCounts_count_accuracy_widgetComponent;
      const inParams:{ warehouseIds?: number[], statusIds?: number[] } = { warehouseIds: [], statusIds: [] };
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_count_tasks_progress_widget') {
      const title = 'Progress';
      const component = InventoryCounts_count_tasks_progress_widgetComponent;
      const inParams:{ inventoryCountId: number } = { inventoryCountId: null };
      if (!isNil(params.get('inventoryCountId'))) {
        const paramValueString = params.get('inventoryCountId');
        inParams.inventoryCountId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_inventory_counts_in_progress_widget') {
      const title = 'Counts in progress';
      const component = InventoryCounts_inventory_counts_in_progress_widgetComponent;
      const inParams:{ warehouseIds?: number[] } = { warehouseIds: [] };
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
