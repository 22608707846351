import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Addresses_ds_get_order_account_vs_order_address_by_orderIdsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async get(inParams: { orderIds: number[] }): Promise<{ result?: { Addresses?: { OrderId?: number, AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, AccountName?: string, AccountLookupCode?: string }[] } }> {

    const missingRequiredInParams = [];
    if (isNil(inParams.orderIds)) {
      missingRequiredInParams.push('\'orderIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Addresses/datasources/ds_get_order_account_vs_order_address_by_orderIds/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
}

