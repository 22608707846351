import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Invoices_ds_invoice_lines_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { invoiceId: number, fullTextSearch?: string }): 
  Promise<{ result: { Id?: number, BillingCodeId?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, Description?: string, InvoiceId?: number, LineNumber?: number, LineTotal?: number, Notes?: string, Quantity?: number, UnitPrice?: number, BillingCode?: { Name?: string }, BillingRecords?: { BillingTaskId?: number }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.invoiceId)) {
      missingRequiredInParams.push('\'invoiceId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Invoices/datasources/ds_invoice_lines_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { invoiceId: number, fullTextSearch?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, BillingCodeId?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, Description?: string, InvoiceId?: number, LineNumber?: number, LineTotal?: number, Notes?: string, Quantity?: number, UnitPrice?: number, BillingCode?: { Name?: string }, BillingRecords?: { BillingTaskId?: number }[] }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.invoiceId)) {
      missingRequiredInParams.push('\'invoiceId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Invoices/datasources/ds_invoice_lines_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { invoiceId: number, fullTextSearch?: string, $keys: number[] }): 
  Promise<{ result: { Id?: number, BillingCodeId?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, Description?: string, InvoiceId?: number, LineNumber?: number, LineTotal?: number, Notes?: string, Quantity?: number, UnitPrice?: number, BillingCode?: { Name?: string }, BillingRecords?: { BillingTaskId?: number }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.invoiceId)) {
      missingRequiredInParams.push('\'invoiceId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Invoices/datasources/ds_invoice_lines_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

