import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';

import { Manifesting_manifesting_library_homeComponent } from './Manifesting.manifesting_library_home.component';
import { Manifesting_containers_by_type_dd_singleComponent } from './Manifesting.containers_by_type_dd_single.component';
import { Manifesting_incoterms_dd_singleComponent } from './Manifesting.incoterms_dd_single.component';
import { Manifesting_label_size_dd_singleComponent } from './Manifesting.label_size_dd_single.component';
import { Manifesting_containers_by_type_dd_multiComponent } from './Manifesting.containers_by_type_dd_multi.component';
import { Manifesting_incoterms_dd_multiComponent } from './Manifesting.incoterms_dd_multi.component';
import { Manifesting_label_size_dd_multiComponent } from './Manifesting.label_size_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Manifesting_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);

  }

  public Manifesting: Manifesting_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openmanifesting_library_home(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'Manifesting_manifesting_library_home',
        component: Manifesting_manifesting_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openmanifesting_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {

    let dialogSize;

    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manifesting_manifesting_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Manifesting_manifesting_library_home') {
      const title = 'Home';
      const component = Manifesting_manifesting_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manifesting_containers_by_type_dd_single') {
      const title = 'Container Types dropdown';
      const component = Manifesting_containers_by_type_dd_singleComponent;
      const inParams:{ containerTypeCategoriesId?: number } = { containerTypeCategoriesId: null };
      if (!isNil(params.get('containerTypeCategoriesId'))) {
        const paramValueString = params.get('containerTypeCategoriesId');
        inParams.containerTypeCategoriesId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manifesting_incoterms_dd_single') {
      const title = 'Incoterms';
      const component = Manifesting_incoterms_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manifesting_label_size_dd_single') {
      const title = 'Label Size dropdown';
      const component = Manifesting_label_size_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manifesting_containers_by_type_dd_multi') {
      const title = 'Container Types dropdown';
      const component = Manifesting_containers_by_type_dd_multiComponent;
      const inParams:{ containerTypeCategoriesId?: number } = { containerTypeCategoriesId: null };
      if (!isNil(params.get('containerTypeCategoriesId'))) {
        const paramValueString = params.get('containerTypeCategoriesId');
        inParams.containerTypeCategoriesId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manifesting_incoterms_dd_multi') {
      const title = 'Incoterms';
      const component = Manifesting_incoterms_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manifesting_label_size_dd_multi') {
      const title = 'Label Size dropdown';
      const component = Manifesting_label_size_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
