import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Replenishments_ds_locations_replenishments_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { materialId?: number[], warehouseIds?: number[], locationId?: number[], projectId?: number, ownerId?: number, fullTextSearch?: string }): 
  Promise<{ result: { Id?: number, ComparisonTypeId?: number, LocationId?: number, MaterialId?: number, MaximumPackagedAmount?: number, MaximumPackingId?: number, MinimumPackagedAmount?: number, MinimumPackagingId?: number, OnDemand?: boolean, ReplenishmentPackagedAmount?: number, ReplenishmentPackagingId?: number, SystemTriggered?: boolean, Location?: { Name?: string, WarehouseId?: number, Warehouse?: { Name?: string } }, Material?: { LookupCode?: string, Name?: string, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } } }, ComparisonType?: { DisplayName?: string } }[] }> 
  {
    let url = `${environment.backendUrl}api/Replenishments/datasources/ds_locations_replenishments_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { materialId?: number[], warehouseIds?: number[], locationId?: number[], projectId?: number, ownerId?: number, fullTextSearch?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ComparisonTypeId?: number, LocationId?: number, MaterialId?: number, MaximumPackagedAmount?: number, MaximumPackingId?: number, MinimumPackagedAmount?: number, MinimumPackagingId?: number, OnDemand?: boolean, ReplenishmentPackagedAmount?: number, ReplenishmentPackagingId?: number, SystemTriggered?: boolean, Location?: { Name?: string, WarehouseId?: number, Warehouse?: { Name?: string } }, Material?: { LookupCode?: string, Name?: string, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } } }, ComparisonType?: { DisplayName?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Replenishments/datasources/ds_locations_replenishments_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { materialId?: number[], warehouseIds?: number[], locationId?: number[], projectId?: number, ownerId?: number, fullTextSearch?: string, $keys: number[] }): 
  Promise<{ result: { Id?: number, ComparisonTypeId?: number, LocationId?: number, MaterialId?: number, MaximumPackagedAmount?: number, MaximumPackingId?: number, MinimumPackagedAmount?: number, MinimumPackagingId?: number, OnDemand?: boolean, ReplenishmentPackagedAmount?: number, ReplenishmentPackagingId?: number, SystemTriggered?: boolean, Location?: { Name?: string, WarehouseId?: number, Warehouse?: { Name?: string } }, Material?: { LookupCode?: string, Name?: string, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } } }, ComparisonType?: { DisplayName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Replenishments/datasources/ds_locations_replenishments_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

