import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_ReportService } from './Utilities.report.index';

import { UccLabels_ucc_label_costcoService } from './UccLabels.report.index';

@Injectable({ providedIn: 'root' })
export class UccLabels_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_ReportService);
  }
    public Utilities: Utilities_ReportService;

  public UccLabels: UccLabels_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ucc_label_costco: UccLabels_ucc_label_costcoService;
  public get ucc_label_costco(): UccLabels_ucc_label_costcoService {
    if(!this._ucc_label_costco) {
      this._ucc_label_costco = this.injector.get(UccLabels_ucc_label_costcoService);
    }
    return this._ucc_label_costco;
  }
}

