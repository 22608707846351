import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class ShippingContainers_ds_container_types_assignment_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { ownerId?: number, projectId?: number, materialId?: number, $top?: number, $skip?: number }): Promise<{ result?: { BoxName?: string, ContainerTypeId?: number, OwnerId?: number, ProjectId?: number, Enabled?: boolean, Width?: number, Length?: number, Height?: number, InnerWidth?: number, InnerLength?: number, InnerHeight?: number, Weight?: number, WeightUom?: string, DimensionUom?: string, WeightCapacity?: number, VolumeCapacity?: number, Volume?: string, Description?: string, CatchAll?: boolean }[], totalCount?: number }> {

    let url = `${environment.backendUrl}api/ShippingContainers/datasources/ds_container_types_assignment_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { ownerId?: number, projectId?: number, materialId?: number, $keys: number[] }): Promise<{ result?: { BoxName?: string, ContainerTypeId?: number, OwnerId?: number, ProjectId?: number, Enabled?: boolean, Width?: number, Length?: number, Height?: number, InnerWidth?: number, InnerLength?: number, InnerHeight?: number, Weight?: number, WeightUom?: string, DimensionUom?: string, WeightCapacity?: number, VolumeCapacity?: number, Volume?: string, Description?: string, CatchAll?: boolean }[] }> {

    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ShippingContainers/datasources/ds_container_types_assignment_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
}

