import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Carriers_ds_get_orders_by_carrierId_serviceTypeId_top1Service {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { carrierId: number, serviceTypeId: number }): 
  Promise<{ result: { Id?: number, PreferredCarrierId?: number, PreferredCarrierServiceTypeId?: number } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.carrierId)) {
      missingRequiredInParams.push('\'carrierId\'');
    }
    if (isNil(inParams.serviceTypeId)) {
      missingRequiredInParams.push('\'serviceTypeId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Carriers/datasources/ds_get_orders_by_carrierId_serviceTypeId_top1/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

