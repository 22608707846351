import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootPrintManager_ds_field_selector_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { Columns: { Key?: string, Name?: string, IsSelected?: boolean }[], $top?: number, $skip?: number }): Promise<{ result?: { Key?: string, Name?: string, IsSelected?: boolean }[], totalCount?: number }> {

    const missingRequiredInParams = [];
    if (isNil(inParams.Columns)) {
      missingRequiredInParams.push('\'Columns\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintManager/datasources/ds_field_selector_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { Columns?: { Key?: string, Name?: string, IsSelected?: boolean }[], $keys: string[] }): Promise<{ result?: { Key?: string, Name?: string, IsSelected?: boolean }[] }> {

    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintManager/datasources/ds_field_selector_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
}

