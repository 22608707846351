import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SerialNumbers_ds_get_serialnumber_location_path_by_serialnumberIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { serialnumberId: number }): 
  Promise<{ result: { Id?: number, LicensePlate?: { Id?: number, LookupCode?: string, TypeId?: number, Location?: { Id?: number, Name?: string, TypeId?: number, Warehouse?: { Id?: number, Name?: string } } } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.serialnumberId)) {
      missingRequiredInParams.push('\'serialnumberId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SerialNumbers/datasources/ds_get_serialnumber_location_path_by_serialnumberId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

