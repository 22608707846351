import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';

import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Inventory_DatasourceService } from './Inventory.datasource.index';

interface IInventory_inventory_by_project_reportServiceInParams {
  projectId: number}

interface IInventory_inventory_by_project_reportServiceData {
  Inventory?: { result?: { WarehouseId?: number, MaterialId?: number, PackagedId?: number, GrossWeight?: number, PackagedAmount?: number, Material?: { Description?: string, LookupCode?: string, Name?: string, Project?: { LookupCode?: string } }, Packaging?: { ShortName?: string }, Warehouse?: { Name?: string }, WeightUom?: { Short_name?: string } }[] }
}

@Injectable({ providedIn: 'root' })
export class Inventory_inventory_by_project_reportService extends ReportBaseService<IInventory_inventory_by_project_reportServiceInParams, IInventory_inventory_by_project_reportServiceData> {

  protected reportReferenceName = 'inventory_by_project_report';
  protected appReferenceName = 'Inventory';

  constructor(
    utils: UtilsService,
    private datasources: Inventory_DatasourceService
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: IInventory_inventory_by_project_reportServiceInParams) {
  }

  protected async getData(inParams: IInventory_inventory_by_project_reportServiceInParams): Promise<IInventory_inventory_by_project_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IInventory_inventory_by_project_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IInventory_inventory_by_project_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        projectId:  $report.inParams.projectId 
      };
      const dsData = await this.datasources.Inventory.ds_inventory_by_project_report.get(dsParams);
      
      data.Inventory = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}

