import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';

import { ExcelOrderImport_excel_order_import_library_homeComponent } from './ExcelOrderImport.excel_order_import_library_home.component';
import { ExcelOrderImport_excel_order_import_gridComponent } from './ExcelOrderImport.excel_order_import_grid.component';
import { ExcelOrderImport_excel_portal_order_import_gridComponent } from './ExcelOrderImport.excel_portal_order_import_grid.component';
import { ExcelOrderImport_inbound_outbound_dd_singleComponent } from './ExcelOrderImport.inbound_outbound_dd_single.component';
import { ExcelOrderImport_lots_dd_singleComponent } from './ExcelOrderImport.lots_dd_single.component';
import { ExcelOrderImport_material_packagings_dd_singleComponent } from './ExcelOrderImport.material_packagings_dd_single.component';
import { ExcelOrderImport_materials_by_project_dd_singleComponent } from './ExcelOrderImport.materials_by_project_dd_single.component';
import { ExcelOrderImport_projects_dd_singleComponent } from './ExcelOrderImport.projects_dd_single.component';
import { ExcelOrderImport_receiving_shipping_dd_singleComponent } from './ExcelOrderImport.receiving_shipping_dd_single.component';
import { ExcelOrderImport_vendorLots_dd_singleComponent } from './ExcelOrderImport.vendorLots_dd_single.component';
import { ExcelOrderImport_warehouses_dd_singleComponent } from './ExcelOrderImport.warehouses_dd_single.component';
import { ExcelOrderImport_inbound_outbound_dd_multiComponent } from './ExcelOrderImport.inbound_outbound_dd_multi.component';
import { ExcelOrderImport_lots_dd_multiComponent } from './ExcelOrderImport.lots_dd_multi.component';
import { ExcelOrderImport_material_packagings_dd_multiComponent } from './ExcelOrderImport.material_packagings_dd_multi.component';
import { ExcelOrderImport_materials_by_project_dd_multiComponent } from './ExcelOrderImport.materials_by_project_dd_multi.component';
import { ExcelOrderImport_projects_dd_multiComponent } from './ExcelOrderImport.projects_dd_multi.component';
import { ExcelOrderImport_receiving_shipping_dd_multiComponent } from './ExcelOrderImport.receiving_shipping_dd_multi.component';
import { ExcelOrderImport_vendorLots_dd_multiComponent } from './ExcelOrderImport.vendorLots_dd_multi.component';
import { ExcelOrderImport_warehouses_dd_multiComponent } from './ExcelOrderImport.warehouses_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class ExcelOrderImport_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);

  }

  public ExcelOrderImport: ExcelOrderImport_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openexcel_order_import_library_home(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'ExcelOrderImport_excel_order_import_library_home',
        component: ExcelOrderImport_excel_order_import_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openexcel_order_import_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {

    let dialogSize;

    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      ExcelOrderImport_excel_order_import_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public openexcel_order_import_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Order Import',
        referenceName: 'ExcelOrderImport_excel_order_import_grid',
        component: ExcelOrderImport_excel_order_import_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openexcel_order_import_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {

    let dialogSize;

    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      ExcelOrderImport_excel_order_import_gridComponent,
      'Order Import',
      mode,
      dialogSize
    )
  }
  public openexcel_portal_order_import_grid(inParams:{ projectId?: number, warehouseId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Portal Order Import',
        referenceName: 'ExcelOrderImport_excel_portal_order_import_grid',
        component: ExcelOrderImport_excel_portal_order_import_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openexcel_portal_order_import_gridDialog(
    inParams:{ projectId?: number, warehouseId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {

    let dialogSize;

    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      ExcelOrderImport_excel_portal_order_import_gridComponent,
      'Portal Order Import',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'ExcelOrderImport_excel_order_import_library_home') {
      const title = 'Home';
      const component = ExcelOrderImport_excel_order_import_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelOrderImport_excel_order_import_grid') {
      const title = 'Order Import';
      const component = ExcelOrderImport_excel_order_import_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelOrderImport_excel_portal_order_import_grid') {
      const title = 'Portal Order Import';
      const component = ExcelOrderImport_excel_portal_order_import_gridComponent;
      const inParams:{ projectId?: number, warehouseId?: number } = { projectId: null, warehouseId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelOrderImport_inbound_outbound_dd_single') {
      const title = 'Inbound Outbound dropdown';
      const component = ExcelOrderImport_inbound_outbound_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelOrderImport_lots_dd_single') {
      const title = 'lots_dd';
      const component = ExcelOrderImport_lots_dd_singleComponent;
      const inParams:{ materialId: number, vendorLotId?: number } = { materialId: null, vendorLotId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('vendorLotId'))) {
        const paramValueString = params.get('vendorLotId');
        inParams.vendorLotId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelOrderImport_material_packagings_dd_single') {
      const title = 'Material Packagings dropdown';
      const component = ExcelOrderImport_material_packagings_dd_singleComponent;
      const inParams:{ materialId?: number } = { materialId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelOrderImport_materials_by_project_dd_single') {
      const title = 'Project Materials dropdown';
      const component = ExcelOrderImport_materials_by_project_dd_singleComponent;
      const inParams:{ projectId: number } = { projectId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelOrderImport_projects_dd_single') {
      const title = 'Projects dropdown';
      const component = ExcelOrderImport_projects_dd_singleComponent;
      const inParams:{ statusId?: number, ownerId?: number } = { statusId: null, ownerId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelOrderImport_receiving_shipping_dd_single') {
      const title = 'Receiving Shipping dropdown';
      const component = ExcelOrderImport_receiving_shipping_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelOrderImport_vendorLots_dd_single') {
      const title = 'vendorLots_dd';
      const component = ExcelOrderImport_vendorLots_dd_singleComponent;
      const inParams:{ materialId: number } = { materialId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelOrderImport_warehouses_dd_single') {
      const title = 'Warehouses dropdown';
      const component = ExcelOrderImport_warehouses_dd_singleComponent;
      const inParams:{ typeId?: number } = { typeId: null };
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelOrderImport_inbound_outbound_dd_multi') {
      const title = 'Inbound Outbound dropdown';
      const component = ExcelOrderImport_inbound_outbound_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelOrderImport_lots_dd_multi') {
      const title = 'lots_dd';
      const component = ExcelOrderImport_lots_dd_multiComponent;
      const inParams:{ materialId: number, vendorLotId?: number } = { materialId: null, vendorLotId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('vendorLotId'))) {
        const paramValueString = params.get('vendorLotId');
        inParams.vendorLotId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelOrderImport_material_packagings_dd_multi') {
      const title = 'Material Packagings dropdown';
      const component = ExcelOrderImport_material_packagings_dd_multiComponent;
      const inParams:{ materialId?: number } = { materialId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelOrderImport_materials_by_project_dd_multi') {
      const title = 'Project Materials dropdown';
      const component = ExcelOrderImport_materials_by_project_dd_multiComponent;
      const inParams:{ projectId: number } = { projectId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelOrderImport_projects_dd_multi') {
      const title = 'Projects dropdown';
      const component = ExcelOrderImport_projects_dd_multiComponent;
      const inParams:{ statusId?: number, ownerId?: number } = { statusId: null, ownerId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelOrderImport_receiving_shipping_dd_multi') {
      const title = 'Receiving Shipping dropdown';
      const component = ExcelOrderImport_receiving_shipping_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelOrderImport_vendorLots_dd_multi') {
      const title = 'vendorLots_dd';
      const component = ExcelOrderImport_vendorLots_dd_multiComponent;
      const inParams:{ materialId: number } = { materialId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelOrderImport_warehouses_dd_multi') {
      const title = 'Warehouses dropdown';
      const component = ExcelOrderImport_warehouses_dd_multiComponent;
      const inParams:{ typeId?: number } = { typeId: null };
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
