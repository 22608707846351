import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Waves_ds_get_shipments_by_shipmentIdsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { shipmentIds: number[] }): 
  Promise<{ result: { Id?: number, CarrierId?: number, ExpectedWarehouseId?: number, LookupCode?: string, StatusId?: number, TrackingIdentifier?: string, WaveId?: number, Wave?: { Id?: number, StatusId?: number }, OrderLookups?: { OrderId?: number, ShipmentId?: number, Order?: { LookupCode?: string } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentIds)) {
      missingRequiredInParams.push('\'shipmentIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Waves/datasources/ds_get_shipments_by_shipmentIds/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { shipmentIds: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CarrierId?: number, ExpectedWarehouseId?: number, LookupCode?: string, StatusId?: number, TrackingIdentifier?: string, WaveId?: number, Wave?: { Id?: number, StatusId?: number }, OrderLookups?: { OrderId?: number, ShipmentId?: number, Order?: { LookupCode?: string } }[] }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentIds)) {
      missingRequiredInParams.push('\'shipmentIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Waves/datasources/ds_get_shipments_by_shipmentIds/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { shipmentIds: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, CarrierId?: number, ExpectedWarehouseId?: number, LookupCode?: string, StatusId?: number, TrackingIdentifier?: string, WaveId?: number, Wave?: { Id?: number, StatusId?: number }, OrderLookups?: { OrderId?: number, ShipmentId?: number, Order?: { LookupCode?: string } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentIds)) {
      missingRequiredInParams.push('\'shipmentIds\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Waves/datasources/ds_get_shipments_by_shipmentIds/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

