import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class ExcelOrderImport_ds_get_packagings_by_materials_projects_packagingsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { projectLookupcodes: string[], materialLookupcodes: string[], materialPackagings: string[] }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, Material?: { Id?: number, ControllerTypeId?: number, LookupCode?: string, Project?: { Id?: number, LookupCode?: string } }, Packaging?: { Id?: number, ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.projectLookupcodes)) {
      missingRequiredInParams.push('\'projectLookupcodes\'');
    }
    if (isNil(inParams.materialLookupcodes)) {
      missingRequiredInParams.push('\'materialLookupcodes\'');
    }
    if (isNil(inParams.materialPackagings)) {
      missingRequiredInParams.push('\'materialPackagings\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelOrderImport/datasources/ds_get_packagings_by_materials_projects_packagings/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { projectLookupcodes: string[], materialLookupcodes: string[], materialPackagings: string[], $top?: number, $skip?: number }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, Material?: { Id?: number, ControllerTypeId?: number, LookupCode?: string, Project?: { Id?: number, LookupCode?: string } }, Packaging?: { Id?: number, ShortName?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.projectLookupcodes)) {
      missingRequiredInParams.push('\'projectLookupcodes\'');
    }
    if (isNil(inParams.materialLookupcodes)) {
      missingRequiredInParams.push('\'materialLookupcodes\'');
    }
    if (isNil(inParams.materialPackagings)) {
      missingRequiredInParams.push('\'materialPackagings\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelOrderImport/datasources/ds_get_packagings_by_materials_projects_packagings/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { projectLookupcodes: string[], materialLookupcodes: string[], materialPackagings: string[], $keys: { MaterialId?: number, PackagingId?: number }[] }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, Material?: { Id?: number, ControllerTypeId?: number, LookupCode?: string, Project?: { Id?: number, LookupCode?: string } }, Packaging?: { Id?: number, ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.projectLookupcodes)) {
      missingRequiredInParams.push('\'projectLookupcodes\'');
    }
    if (isNil(inParams.materialLookupcodes)) {
      missingRequiredInParams.push('\'materialLookupcodes\'');
    }
    if (isNil(inParams.materialPackagings)) {
      missingRequiredInParams.push('\'materialPackagings\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelOrderImport/datasources/ds_get_packagings_by_materials_projects_packagings/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

