import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Surveys_ds_submitted_surveys_grid_tempService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { entities?: { type?: string, ids?: number[] }[], operationContextTypes?: string[], dates?: { dateFilter?: string, fromDate?: string, toDate?: string }, warehouseIds?: number[], $top?: number, $skip?: number }): Promise<{ result?: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, SurveyDefinition_Name?: string, OperationContextType_Name?: string, ContextAppointment_LookupCode?: string, ContextLicensePlate_LookupCode?: string, ContextLocation_Name?: string, ContextProject_LookupCode?: string, ContextShipment_LookupCode?: string, ContextShippingContainer_LookupCode?: string, ContextTask_Id?: number, ContextTask_OperationCode_Name?: string, Equipment_Name?: string }[], totalCount?: number }> {

    let url = `${environment.backendUrl}api/Surveys/datasources/ds_submitted_surveys_grid_temp/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { entities?: { type?: string, ids?: number[] }[], operationContextTypes?: string[], dates?: { dateFilter?: string, fromDate?: string, toDate?: string }, warehouseIds?: number[], $keys: number[] }): Promise<{ result?: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, SurveyDefinition_Name?: string, OperationContextType_Name?: string, ContextAppointment_LookupCode?: string, ContextLicensePlate_LookupCode?: string, ContextLocation_Name?: string, ContextProject_LookupCode?: string, ContextShipment_LookupCode?: string, ContextShippingContainer_LookupCode?: string, ContextTask_Id?: number, ContextTask_OperationCode_Name?: string, Equipment_Name?: string }[] }> {

    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Surveys/datasources/ds_submitted_surveys_grid_temp/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
}

