<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"

[rowCanExpand]="true"
>
    <ng-container topToolbar>
    <div data-cy="tool-id-unpick" *ngIf="!topToolbar.unpick.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.unpick.control.readOnly"
            [ngStyle]="topToolbar.unpick.control.styles.style"
            [ngClass]="topToolbar.unpick.control.styles.classes"
            (click)="on_unpick_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.unpick.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.unpick.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.unpick.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-transfer" *ngIf="!topToolbar.transfer.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.transfer.control.readOnly"
            [ngStyle]="topToolbar.transfer.control.styles.style"
            [ngClass]="topToolbar.transfer.control.styles.classes"
            (click)="on_transfer_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.transfer.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.transfer.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.transfer.control.label}}</div>
      </div>
    
    </button>
    </div>
  </ng-container>


  <ng-container gridColumnDef="image_preview">

    <ng-template gridCellDisplayControlDef let-row>
    <a (click)="openImageViewer(row.cells.image_preview.displayControl.src)">
    <img [src]="row.cells.image_preview.displayControl.src"
            data-cy="image" class="datex-image"
            [ngStyle]="row.cells.image_preview.displayControl.styles.style"
            [ngClass]="row.cells.image_preview.displayControl.styles.classes"
            onerror="this.style.display='none'"
            onload="this.style.display='inline'">
    </a>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.material.displayControl.styles.style"
          [ngClass]="row.cells.material.displayControl.styles.classes">{{row.cells.material.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.lot.displayControl.styles.style"
          [ngClass]="row.cells.lot.displayControl.styles.classes">{{row.cells.lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="packaging">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.packaging.displayControl.styles.style"
          [ngClass]="row.cells.packaging.displayControl.styles.classes">{{row.cells.packaging.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="verified_quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.verified_quantity.displayControl.styles.style"
          [ngClass]="row.cells.verified_quantity.displayControl.styles.classes">{{row.cells.verified_quantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['verified_quantity_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.verified_quantity.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.verified_quantity.editControl.placeholder}}"
            [ngStyle]="row.cells.verified_quantity.editControl.styles.style"
            [ngClass]="row.cells.verified_quantity.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="add_verified">

    <ng-template gridCellDisplayControlDef let-row>
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="row.cells.add_verified.displayControl.readOnly"
            [ngStyle]="row.cells.add_verified.displayControl.styles.style"
            [ngClass]="row.cells.add_verified.displayControl.styles.classes"
            (click)="row.on_add_verified_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="row.cells.add_verified.displayControl.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{row.cells.add_verified.displayControl.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{row.cells.add_verified.displayControl.label}}</div>
      </div>
    
    </button>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>

<ng-template expandableRowDef
              let-row>
    <FootPrintManager-scanned_values_grid
    [scannedValues]="row.$rowExpand_FootPrintManager_scanned_values_grid_inParams_scannedValues"
    [expectedValues]="row.$rowExpand_FootPrintManager_scanned_values_grid_inParams_expectedValues"
    ($refreshEvent)="row.refresh()">>
  </FootPrintManager-scanned_values_grid>
</ng-template>

</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
