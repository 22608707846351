import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class PackVerification_ds_get_shippingcontainer_contentService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { shippingContainerId: number }): 
  Promise<{ result: { PackagedAmount?: number, LotId?: number, PackagedId?: number, LicensePlateId?: number, Lot?: { MaterialId?: number, LookupCode?: string, VendorLotId?: number, Material?: { LookupCode?: string, ControllerTypeId?: number } }, Packaged?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shippingContainerId)) {
      missingRequiredInParams.push('\'shippingContainerId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/PackVerification/datasources/ds_get_shippingcontainer_content/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { shippingContainerId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { PackagedAmount?: number, LotId?: number, PackagedId?: number, LicensePlateId?: number, Lot?: { MaterialId?: number, LookupCode?: string, VendorLotId?: number, Material?: { LookupCode?: string, ControllerTypeId?: number } }, Packaged?: { Name?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shippingContainerId)) {
      missingRequiredInParams.push('\'shippingContainerId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/PackVerification/datasources/ds_get_shippingcontainer_content/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { shippingContainerId: number, $keys: { Lot?: { MaterialId?: number, Material?: { LookupCode?: string, ControllerTypeId?: number }, LookupCode?: string, VendorLotId?: number }, LotId?: number, PackagedId?: number, Packaged?: { Name?: string }, LicensePlateId?: number }[] }): 
  Promise<{ result: { PackagedAmount?: number, LotId?: number, PackagedId?: number, LicensePlateId?: number, Lot?: { MaterialId?: number, LookupCode?: string, VendorLotId?: number, Material?: { LookupCode?: string, ControllerTypeId?: number } }, Packaged?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shippingContainerId)) {
      missingRequiredInParams.push('\'shippingContainerId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/PackVerification/datasources/ds_get_shippingcontainer_content/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

