import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class PurchaseOrders_ds_inbound_orders_details_grid_countService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], fullTextSearch?: string, fromDate?: string, toDate?: string, take?: number, skip?: number, orderClassIds?: number[], dateType?: string, reference?: string, orderIds?: number[] }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, Notes?: string, PackagedAmount?: number, Price?: number }, totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/PurchaseOrders/datasources/ds_inbound_orders_details_grid_count/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

