import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { UccLabels_ucc_create_labelsService } from './UccLabels.flow.index';

import { $types } from './UccLabels.types'

@Injectable({ providedIn: 'root' })
export class UccLabels_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public UccLabels: UccLabels_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ucc_create_labels: UccLabels_ucc_create_labelsService;
  public async ucc_create_labels(inParams: { shipment_id?: number, order_id?: number, quantity?: number }): Promise< { Labels?: { ShipmentId?: number, ShippingContainerId?: number, ShippingContainerLookupCode?: string }[] }> {
    if(!this._ucc_create_labels) {
      this._ucc_create_labels = this.injector.get(UccLabels_ucc_create_labelsService);
    }
    return this._ucc_create_labels.run(inParams);
  }
}
