import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Owners_ds_get_project_top1Service {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: {  }): 
  Promise<{ result: { Id?: number, LookupCode?: string, Name?: string, OwnerId?: number, Owner?: { LookupCode?: string, Name?: string } } }> 
  {
    let url = `${environment.backendUrl}api/Owners/datasources/ds_get_project_top1/get`;
    
    const options = {
    }
    
    const body = null;
    
    return this.utils.http.post(url, body, options);
    
  }

}

