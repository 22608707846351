import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class ExcelInventoryImport_ds_get_material_by_id_top1Service {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { id: number }): 
  Promise<{ result: { Id?: number, ControllerTypeId?: number, IsFixedWeight?: boolean } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.id)) {
      missingRequiredInParams.push('\'id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelInventoryImport/datasources/ds_get_material_by_id_top1/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

