import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Surveys_ds_get_survey_by_surveyIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { surveyId: number }): 
  Promise<{ result: { id?: number, createdSysDateTime?: string, createdSysUser?: string, modifiedSysDateTime?: string, modifiedSysUser?: string, SurveyDefinition?: { description?: string, name?: string }, OperationContextType?: { Name?: string }, ContextAppointment?: { LookupCode?: string }, ContextLicensePlate?: { LookupCode?: string }, ContextLocation?: { Name?: string }, ContextProject?: { LookupCode?: string }, ContextShipment?: { LookupCode?: string }, ContextShippingContainer?: { LookupCode?: string }, ContextTask?: { Id?: number, OperationCode?: { Name?: string } }, Equipment?: { LookupCode?: string } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.surveyId)) {
      missingRequiredInParams.push('\'surveyId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Surveys/datasources/ds_get_survey_by_surveyId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

