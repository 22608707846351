import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_invoice_get_billingtask_detailsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { billingTaskId: number }): 
  Promise<{ result: { Id?: number, MaterialId?: number, PackagingId?: number, ProjectId?: number, ShipmentId?: number, Packaging?: { Id?: number, DimensionUomId?: number, Length?: number, Weight?: number, WeightUomId?: number, Width?: number, WeightUom?: { Id?: number, Name?: string, Short_name?: string }, DimensionsUom?: { Id?: number, Name?: string, Short_name?: string } }, BillingContractLine?: { Id?: number, BillingAggregationStrategyId?: number, BillingAggregationStrategy?: { Id?: number, EnumName?: string } }, Project?: { LookupCode?: string, Name?: string }, order?: { OrderId?: number, ShipmentId?: number, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string } } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.billingTaskId)) {
      missingRequiredInParams.push('\'billingTaskId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_invoice_get_billingtask_details/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

