import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class ExcelLocationImport_ds_get_locations_by_name_and_warehouseIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { warehouseId?: number, name?: string, typeIds?: number[] }): 
  Promise<{ result: { Id?: number, Name?: string } }> 
  {
    let url = `${environment.backendUrl}api/ExcelLocationImport/datasources/ds_get_locations_by_name_and_warehouseId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

