<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
      <div data-cy="tool-id-fail_verification" *ngIf="!toolbar.fail_verification.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.fail_verification.control.readOnly"
              [ngStyle]="toolbar.fail_verification.control.styles.style"
              [ngClass]="toolbar.fail_verification.control.styles.classes"
              (click)="on_fail_verification_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.fail_verification.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.fail_verification.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.fail_verification.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-input_scan" *ngIf="!fields.input_scan.hidden" 
                            class="field-container double {{fields.input_scan.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.input_scan.styles.style"
                            [ngClass]="fields.input_scan.styles.classes">
                        <div class="label-container"
                              title="{{fields.input_scan.label}}{{fields.input_scan.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.input_scan.label}}">{{fields.input_scan.label}}<span *ngIf="fields.input_scan.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="input_scan"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.input_scan.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.input_scan.control.placeholder}}"
                                [ngStyle]="fields.input_scan.control.styles.style"
                                [ngClass]="fields.input_scan.control.styles.classes"> 
                        <ng-container *ngIf="fields.input_scan.invalid">
                          <ng-container *ngFor="let error of fields.input_scan.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.container_contents.hidden" class="tab" data-cy="tab-container_contents">
              <h2 [className]="tabs.container_contents.active? 'active': ''" (click)="tabs.container_contents.activate()">{{tabs.container_contents.title}}</h2>
            </div>
            <div *ngIf="!tabs.invalid_serial_numbers.hidden" class="tab" data-cy="tab-invalid_serial_numbers">
              <h2 [className]="tabs.invalid_serial_numbers.active? 'active': ''" (click)="tabs.invalid_serial_numbers.activate()">{{tabs.invalid_serial_numbers.title}}</h2>
            </div>
            <div *ngIf="!tabs.scanned_values.hidden" class="tab" data-cy="tab-scanned_values">
              <h2 [className]="tabs.scanned_values.active? 'active': ''" (click)="tabs.scanned_values.activate()">{{tabs.scanned_values.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-pack_verification_shipping_container_contents_grid *ngIf="tabs.container_contents.active"
              #$tabs_container_contents
              [shippingContainerId]="$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_shippingContainerId"
              [canAddManually]="$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_canAddManually"
              [startVerificationTaskId]="$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_startVerificationTaskId"
              [isReadOnly]="$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_isReadOnly"
              [scannedValues]="$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_scannedValues"
              [isBlind]="$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_isBlind"
              [expectedSerialNumbers]="$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_expectedSerialNumbers"
              [locationId]="$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_locationId"
              (refocus)="set_focus($event)"
              ($refreshEvent)="refresh(false, false, '$tabs_container_contents')">
              </FootPrintManager-pack_verification_shipping_container_contents_grid>
              <FootPrintManager-scanned_values_grid *ngIf="tabs.invalid_serial_numbers.active"
              #$tabs_invalid_serial_numbers
              [scannedValues]="$tabs_invalid_serial_numbers_scanned_values_grid_inParams_scannedValues"
              ($refreshEvent)="refresh(false, false, '$tabs_invalid_serial_numbers')">
              </FootPrintManager-scanned_values_grid>
              <FootPrintManager-scanned_values_grid *ngIf="tabs.scanned_values.active"
              #$tabs_scanned_values
              [scannedValues]="$tabs_scanned_values_scanned_values_grid_inParams_scannedValues"
              ($refreshEvent)="refresh(false, false, '$tabs_scanned_values')">
              </FootPrintManager-scanned_values_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>