import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Materials_ds_get_packagings_by_material_lookupcode_packaging_names_projectIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { materialLookupcodes: string[], materialPackagings: string[], projectId: number }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, Material?: { Id?: number, ControllerTypeId?: number, LookupCode?: string, Project?: { Id?: number, LookupCode?: string } }, Packaging?: { Id?: number, ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.materialLookupcodes)) {
      missingRequiredInParams.push('\'materialLookupcodes\'');
    }
    if (isNil(inParams.materialPackagings)) {
      missingRequiredInParams.push('\'materialPackagings\'');
    }
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Materials/datasources/ds_get_packagings_by_material_lookupcode_packaging_names_projectId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { materialLookupcodes: string[], materialPackagings: string[], projectId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, Material?: { Id?: number, ControllerTypeId?: number, LookupCode?: string, Project?: { Id?: number, LookupCode?: string } }, Packaging?: { Id?: number, ShortName?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.materialLookupcodes)) {
      missingRequiredInParams.push('\'materialLookupcodes\'');
    }
    if (isNil(inParams.materialPackagings)) {
      missingRequiredInParams.push('\'materialPackagings\'');
    }
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Materials/datasources/ds_get_packagings_by_material_lookupcode_packaging_names_projectId/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { materialLookupcodes: string[], materialPackagings: string[], projectId: number, $keys: { MaterialId?: number, PackagingId?: number }[] }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, Material?: { Id?: number, ControllerTypeId?: number, LookupCode?: string, Project?: { Id?: number, LookupCode?: string } }, Packaging?: { Id?: number, ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.materialLookupcodes)) {
      missingRequiredInParams.push('\'materialLookupcodes\'');
    }
    if (isNil(inParams.materialPackagings)) {
      missingRequiredInParams.push('\'materialPackagings\'');
    }
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Materials/datasources/ds_get_packagings_by_material_lookupcode_packaging_names_projectId/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

