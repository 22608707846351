import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class PalletTransactions_ds_pallet_transactions_shipment_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { shipment_id?: number, aggregate?: boolean, full_text_search?: string, $top?: number, $skip?: number }): Promise<{ result?: { PalletClassId?: number, In?: number, Out?: number, Date?: string, Employee?: string, PalletClassName?: string, Reference?: string, Notes?: string, Amount?: number, Id?: number }[], totalCount?: number }> {

    let url = `${environment.backendUrl}api/PalletTransactions/datasources/ds_pallet_transactions_shipment_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { shipment_id?: number, aggregate?: boolean, full_text_search?: string, $keys: number[] }): Promise<{ result?: { PalletClassId?: number, In?: number, Out?: number, Date?: string, Employee?: string, PalletClassName?: string, Reference?: string, Notes?: string, Amount?: number, Id?: number }[] }> {

    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/PalletTransactions/datasources/ds_pallet_transactions_shipment_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
}

