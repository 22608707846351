import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Addresses_ds_contact_addresses_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { entity: string, entityId: number, typeIds?: number[], fullTextSearch?: string }): 
  Promise<{ result: { Id?: number, AddressId?: number, FirstName?: string, LastName?: string, Notes?: string, OwnerEntity?: string, OwnerId?: number, PrimaryEmail?: string, PrimaryTelephone?: string, TypeId?: number, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string }, Type?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.entity)) {
      missingRequiredInParams.push('\'entity\'');
    }
    if (isNil(inParams.entityId)) {
      missingRequiredInParams.push('\'entityId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Addresses/datasources/ds_contact_addresses_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { entity: string, entityId: number, typeIds?: number[], fullTextSearch?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, AddressId?: number, FirstName?: string, LastName?: string, Notes?: string, OwnerEntity?: string, OwnerId?: number, PrimaryEmail?: string, PrimaryTelephone?: string, TypeId?: number, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string }, Type?: { Name?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.entity)) {
      missingRequiredInParams.push('\'entity\'');
    }
    if (isNil(inParams.entityId)) {
      missingRequiredInParams.push('\'entityId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Addresses/datasources/ds_contact_addresses_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { entity: string, entityId: number, typeIds?: number[], fullTextSearch?: string, $keys: number[] }): 
  Promise<{ result: { Id?: number, AddressId?: number, FirstName?: string, LastName?: string, Notes?: string, OwnerEntity?: string, OwnerId?: number, PrimaryEmail?: string, PrimaryTelephone?: string, TypeId?: number, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string }, Type?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.entity)) {
      missingRequiredInParams.push('\'entity\'');
    }
    if (isNil(inParams.entityId)) {
      missingRequiredInParams.push('\'entityId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Addresses/datasources/ds_contact_addresses_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

