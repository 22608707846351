<div class="shell-container">

  <div class="shell-header">
    <div class="logo-container">
      <img src="{{logo}}">
    </div>
    <div id="title"><span class="appname" class="appname branded">{{title}}</span></div>
    <div class="custom-header">
      <div class="shell-header-tools">
        <button id="doc360_help_btn" class="shell-button hide">
          <i class="icon icon-ic_fluent_question_circle_20_regular"></i>
        </button>
      </div>
      <div class="menu">
        <button [matMenuTriggerFor]="menu" class="shell-button user-account">
          <div class="user-name">{{ account?.username }}</div>
          <div class="user-icon" title="{{ account?.username }}">
            <div class="icon-container"><i class="icon icon-ic_fluent_person_20_regular"></i></div>
          </div>
        </button>
        <mat-menu #menu="matMenu"
                  class="options-menu">
          <button mat-menu-item
                  (click)="onLogoutClicked()"><i class="icon icon-ic_fluent_sign_out_20_regular"></i>
            <span>Sign out</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>

  <div class="shell-body">

    <div data-cy="main-menu" class="main-menu" [class.open]="showMainMenu">

      <div data-cy="hamburger" class="menu-item expander" (click)="onHamburgerClicked()">
        <div class="menu-icon">
          <i class="icon icon-ic_fluent_navigation_20_regular"></i>
        </div>
      </div>

      <div data-cy="menu-item-home" class="menu-item" (click)="onOpenHomeClicked()" matTooltip="{{showMainMenu ? null : homeMenubarItem.label}}" matTooltipShowDelay="500" matTooltipHideDelay="250" matTooltipClass="main-menu-tooltip">
        <div class="menu-icon">
          <i class="icon {{homeMenubarItem.icon}}"></i>
        </div>
        <div class="menu-label">{{homeMenubarItem.label}}</div>
      </div>

      <ng-container *ngFor="let menubarItem of items">
        <div *ngIf="!menubarItem.hidden" [attr.data-cy]="'menu-item-id-' + menubarItem.id" class="menu-item" [class.active]="menubarItem === currentMenubarItem" (click)="onMenubarItemClicked(menubarItem)" matTooltip="{{showMainMenu ? null : menubarItem.label}}" matTooltipShowDelay="500" matTooltipHideDelay="250" matTooltipClass="main-menu-tooltip">
          <div class="menu-icon">
            <i class="icon {{menubarItem.icon}}"></i>
          </div>
          <div class="menu-label">{{menubarItem.label}}</div>
          <div class="menu-sub-icon" *ngIf="menubarItem.items">
            <i class="icon icon-ic_fluent_caret_right_20_filled"></i>
          </div>
        </div>
      </ng-container>
    </div>

    <div data-cy="sub-menu" *ngIf="currentMenubarItem" class="sub-menu">
      <ng-container *ngFor="let menubarItem of currentMenubarItem.items">
        <div *ngIf="!menubarItem.hidden" [attr.data-cy]="'sub-menu-item-id-' + menubarItem.parent.id + '_' + menubarItem.id"  class="menu-item" (click)="onMenubarItemClicked(menubarItem)">
          <div class="menu-icon">
            <i class="icon {{menubarItem.icon}}"></i>
          </div>
          <div class="menu-label">{{menubarItem.label}}</div>
        </div>
      </ng-container>
    </div>

    <div class="shell-content">

      <div class="breadcrumbs">
        <div *ngFor="let crumb of crumbs; let last = last" class="crumb"
           [class.active]="crumb.active" 
           (click)="onCrumbClicked(crumb)"><span class="crumb-title" title="{{crumb.title}}">{{crumb.title}}</span><i *ngIf="!last" class="icon icon-ic_fluent_chevron_right_20_filled"></i>
        </div>
      </div>

      <div class="workspace">
        <ng-template #closeTool>
          <div class="close-btn-container"
               (click)="onCloseClicked()">
            <i class="icon icon-ic_fluent_dismiss_20_regular"></i>
          </div>
        </ng-template>
        <ng-container #host></ng-container>
      </div>

    </div>

    <div class="shell-status-bar">

      <div class="shell-status-message"></div>
      <div class="shell-status-loader">
        <div class="loading-bar-container">
          <ngx-loading-bar [value]="loadingBarProgress" [includeSpinner]="false"></ngx-loading-bar>
        </div>
      </div>
    </div>

  </div>

</div>
