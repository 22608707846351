import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootPrintManager_ds_entity_user_defined_field_values_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { EntityType: string, EntityKeys: { name?: string, value?: any }[], $top?: number, $skip?: number }): Promise<{ result?: { Id?: number, UdfId?: number, EntityId?: string, UdfValue?: string, UdfName?: string, UdfType?: string }[], totalCount?: number }> {

    const missingRequiredInParams = [];
    if (isNil(inParams.EntityType)) {
      missingRequiredInParams.push('\'EntityType\'');
    }
    if (isNil(inParams.EntityKeys)) {
      missingRequiredInParams.push('\'EntityKeys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintManager/datasources/ds_entity_user_defined_field_values_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { EntityType?: string, EntityKeys?: { name?: string, value?: any }[], $keys: { Id?: number, UdfId?: number, EntityId?: string, UdfValue?: string, UdfName?: string, UdfType?: string }[] }): Promise<{ result?: { Id?: number, UdfId?: number, EntityId?: string, UdfValue?: string, UdfName?: string, UdfType?: string }[] }> {

    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintManager/datasources/ds_entity_user_defined_field_values_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
}

