import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_outbound_details_bill_of_lading_report_advancedService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async get(inParams: { orderId?: number }): Promise<{ result?: { Contents?: { ShipmentId?: number, LicensePlateId?: number, LotId?: number, PackagedId?: number, Amount?: number, PackagedAmount?: number, LotLookupCode?: string, MaterialLookupCode?: string, MaterialDescription?: string, NetWeight?: number, GrossWeight?: number, WeightUomId?: number, OrderId?: number, LicensePlateLookupCode?: string, WeightUom?: string, Uom?: string, SerialCount?: number, IsFixedWeight?: boolean }[] } }> {

    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_outbound_details_bill_of_lading_report_advanced/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
}

