import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';

import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Invoices_DatasourceService } from './Invoices.datasource.index';

interface IInvoices_invoice_report_non_expandedServiceInParams {
  invoiceId: number}

interface IInvoices_invoice_report_non_expandedServiceData {
  header?: { result?: { Id?: number, DueDate?: string, InvoiceDate?: string, LookupCode?: string, Notes?: string, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string, Enterprise?: { Name?: string } } } } }
  owner_address?: { result?: { Id?: number, Project?: { Id?: number, Owner?: { Id?: number, OwnersContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } } }
  line_details?: { result?: { Id?: number, CreatedSysDateTime?: string, Description?: string, LineTotal?: number, Quantity?: number, UnitPrice?: number, BillingCode?: { Name?: string }, BillingRecords?: { BillingTaskId?: number, InvoiceLineId?: number, BillingTask?: { CreatedSysDateTime?: string, MaterialId?: number, Notes?: string, ShipmentId?: number, LicensePlate?: { LookupCode?: string }, Lot?: { LookupCode?: string }, Material?: { Description?: string, LookupCode?: string }, Shipment?: { LookupCode?: string }, Order?: { LookupCode?: string, OwnerReference?: string }, OperationCode?: { Name?: string } } }[] }[] }
  warehouse?: { result?: { Id?: number, InvoiceLines?: { Id?: number, BillingRecords?: { BillingTaskId?: number, BillingTask?: { WarehouseId?: number } }[] }[], Warehouse?: { Id?: number, Name?: string, WarehousesContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } }
  warehouse_only?: { result?: { Id?: number, Name?: string, WarehousesContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } }
}

@Injectable({ providedIn: 'root' })
export class Invoices_invoice_report_non_expandedService extends ReportBaseService<IInvoices_invoice_report_non_expandedServiceInParams, IInvoices_invoice_report_non_expandedServiceData> {

  protected reportReferenceName = 'invoice_report_non_expanded';
  protected appReferenceName = 'Invoices';

  constructor(
    utils: UtilsService,
    private datasources: Invoices_DatasourceService
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: IInvoices_invoice_report_non_expandedServiceInParams) {
  }

  protected async getData(inParams: IInvoices_invoice_report_non_expandedServiceInParams): Promise<IInvoices_invoice_report_non_expandedServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IInvoices_invoice_report_non_expandedServiceInParams } = {
      inParams: inParams
    };

    const data: IInvoices_invoice_report_non_expandedServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        invoiceId:  $report.inParams.invoiceId 
      };
      const dsData = await this.datasources.Invoices.ds_get_invoice_header_by_invoiceId.get(dsParams);
      
      data.header = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        invoiceId:  $report.inParams.invoiceId 
      };
      const dsData = await this.datasources.Invoices.ds_get_owner_address_by_invoiceId.get(dsParams);
      
      data.owner_address = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        invoiceId:  $report.inParams.invoiceId 
      };
      const dsData = await this.datasources.Invoices.ds_get_invoice_line_details_by_invoiceId_ne.get(dsParams);
      
      data.line_details = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        invoiceId:  $report.inParams.invoiceId 
      };
      const dsData = await this.datasources.Invoices.ds_get_first_warehouse_address_by_invoiceId.get(dsParams);
      
      data.warehouse = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
      };
      const dsData = await this.datasources.Invoices.ds_get_first_warehouse.get(dsParams);
      
      data.warehouse_only = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}

