import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class ExcelLocationImport_ds_get_measurementunits_by_shortnamesService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { measurementUnitShortNames: string[] }): 
  Promise<{ result: { Id?: number, Short_name?: string }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.measurementUnitShortNames)) {
      missingRequiredInParams.push('\'measurementUnitShortNames\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelLocationImport/datasources/ds_get_measurementunits_by_shortnames/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { measurementUnitShortNames: string[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, Short_name?: string }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.measurementUnitShortNames)) {
      missingRequiredInParams.push('\'measurementUnitShortNames\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelLocationImport/datasources/ds_get_measurementunits_by_shortnames/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { measurementUnitShortNames: string[], $keys: number[] }): 
  Promise<{ result: { Id?: number, Short_name?: string }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.measurementUnitShortNames)) {
      missingRequiredInParams.push('\'measurementUnitShortNames\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelLocationImport/datasources/ds_get_measurementunits_by_shortnames/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

