import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Addresses_ds_get_contact_types_by_nameService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { name: string }): 
  Promise<{ result: { Id?: number, Name?: string }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.name)) {
      missingRequiredInParams.push('\'name\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Addresses/datasources/ds_get_contact_types_by_name/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { name: string, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, Name?: string }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.name)) {
      missingRequiredInParams.push('\'name\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Addresses/datasources/ds_get_contact_types_by_name/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { name: string, $keys: number[] }): 
  Promise<{ result: { Id?: number, Name?: string }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.name)) {
      missingRequiredInParams.push('\'name\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Addresses/datasources/ds_get_contact_types_by_name/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

