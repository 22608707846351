import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';

import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Waves_DatasourceService } from './Waves.datasource.index';

interface IWaves_pick_slip_by_wave_id_consolidated_reportServiceInParams {
  waveId: number}

interface IWaves_pick_slip_by_wave_id_consolidated_reportServiceData {
  PickSlip?: { result?: { ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, MaterialId?: number, ExpectedSourceLocationId?: number, LotId?: number, ExpectedSourceLicensePlateId?: number, ExpectedSourceLocation?: { Name?: string, PickSequence?: number }, ExpectedPackagedPack?: { ShortName?: string }, Material?: { LookupCode?: string, Description?: string }, Lot?: { LookupCode?: string }, MaterialWeights?: { MaterialId?: number, PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, ShippingWeight?: number, Weight?: number } }[] }
}

@Injectable({ providedIn: 'root' })
export class Waves_pick_slip_by_wave_id_consolidated_reportService extends ReportBaseService<IWaves_pick_slip_by_wave_id_consolidated_reportServiceInParams, IWaves_pick_slip_by_wave_id_consolidated_reportServiceData> {

  protected reportReferenceName = 'pick_slip_by_wave_id_consolidated_report';
  protected appReferenceName = 'Waves';

  constructor(
    utils: UtilsService,
    private datasources: Waves_DatasourceService
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: IWaves_pick_slip_by_wave_id_consolidated_reportServiceInParams) {
  }

  protected async getData(inParams: IWaves_pick_slip_by_wave_id_consolidated_reportServiceInParams): Promise<IWaves_pick_slip_by_wave_id_consolidated_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IWaves_pick_slip_by_wave_id_consolidated_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IWaves_pick_slip_by_wave_id_consolidated_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        waveId:  $report.inParams.waveId 
      };
      const dsData = await this.datasources.Waves.ds_consolidated_outbound_pick_slip_by_wave_id_report.get(dsParams);
      
      data.PickSlip = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}

