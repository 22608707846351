import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Materials_ds_materials_by_base_packaging_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { projectId?: number, ownerId?: number, fullTextSearch?: string, materialIds?: number[] }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, DimensionUomId?: number, Height?: number, Length?: number, PalletHigh?: number, PalletTie?: number, ShippingVolume?: number, ShippingWeight?: number, UpcCode?: string, UseAsShippingContainer?: boolean, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number, Width?: number, Material?: { Id?: number, AllocationStrategyId?: number, ControllerTypeId?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, Description?: string, GlobalMaterialId?: number, IsFixedLength?: boolean, IsFixedVolume?: boolean, IsFixedWeight?: boolean, LookupCode?: string, MaterialGroupId?: number, Name?: string, ProjectId?: number, ShelfLifeSpan?: number, StatusId?: number, Project?: { Id?: number, LookupCode?: string, Name?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } }, ControllerType?: { Id?: number, Name?: string }, GlobalMaterial?: { Id?: number, Name?: string }, MaterialGroup?: { Name?: string }, Status?: { Id?: number, Name?: string }, AllocationStrategy?: { Name?: string } }, Packaging?: { Id?: number, ShortName?: string }, DimensionsUom?: { Id?: number, Short_name?: string }, VolumeUom?: { Id?: number, Short_name?: string }, WeightUom?: { Id?: number, Short_name?: string } }[] }> 
  {
    let url = `${environment.backendUrl}api/Materials/datasources/ds_materials_by_base_packaging_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { projectId?: number, ownerId?: number, fullTextSearch?: string, materialIds?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, DimensionUomId?: number, Height?: number, Length?: number, PalletHigh?: number, PalletTie?: number, ShippingVolume?: number, ShippingWeight?: number, UpcCode?: string, UseAsShippingContainer?: boolean, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number, Width?: number, Material?: { Id?: number, AllocationStrategyId?: number, ControllerTypeId?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, Description?: string, GlobalMaterialId?: number, IsFixedLength?: boolean, IsFixedVolume?: boolean, IsFixedWeight?: boolean, LookupCode?: string, MaterialGroupId?: number, Name?: string, ProjectId?: number, ShelfLifeSpan?: number, StatusId?: number, Project?: { Id?: number, LookupCode?: string, Name?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } }, ControllerType?: { Id?: number, Name?: string }, GlobalMaterial?: { Id?: number, Name?: string }, MaterialGroup?: { Name?: string }, Status?: { Id?: number, Name?: string }, AllocationStrategy?: { Name?: string } }, Packaging?: { Id?: number, ShortName?: string }, DimensionsUom?: { Id?: number, Short_name?: string }, VolumeUom?: { Id?: number, Short_name?: string }, WeightUom?: { Id?: number, Short_name?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Materials/datasources/ds_materials_by_base_packaging_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { projectId?: number, ownerId?: number, fullTextSearch?: string, materialIds?: number[], $keys: { MaterialId?: number, PackagingId?: number }[] }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, DimensionUomId?: number, Height?: number, Length?: number, PalletHigh?: number, PalletTie?: number, ShippingVolume?: number, ShippingWeight?: number, UpcCode?: string, UseAsShippingContainer?: boolean, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number, Width?: number, Material?: { Id?: number, AllocationStrategyId?: number, ControllerTypeId?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, Description?: string, GlobalMaterialId?: number, IsFixedLength?: boolean, IsFixedVolume?: boolean, IsFixedWeight?: boolean, LookupCode?: string, MaterialGroupId?: number, Name?: string, ProjectId?: number, ShelfLifeSpan?: number, StatusId?: number, Project?: { Id?: number, LookupCode?: string, Name?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } }, ControllerType?: { Id?: number, Name?: string }, GlobalMaterial?: { Id?: number, Name?: string }, MaterialGroup?: { Name?: string }, Status?: { Id?: number, Name?: string }, AllocationStrategy?: { Name?: string } }, Packaging?: { Id?: number, ShortName?: string }, DimensionsUom?: { Id?: number, Short_name?: string }, VolumeUom?: { Id?: number, Short_name?: string }, WeightUom?: { Id?: number, Short_name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Materials/datasources/ds_materials_by_base_packaging_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

