import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Owners_ds_projects_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { projectStatusId?: number, fullTextSearch?: string, ownerId?: number, projectIds?: number[] }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, LookupCode?: string, Name?: string, Notes?: string, StatusId?: number, Status?: { Label?: string }, Owner?: { LookupCode?: string, Name?: string }, LicenseplateCount?: { TotalLicenseplates?: number, Lot?: { Material?: { ProjectId?: number } } } }[] }> 
  {
    let url = `${environment.backendUrl}api/Owners/datasources/ds_projects_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { projectStatusId?: number, fullTextSearch?: string, ownerId?: number, projectIds?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, LookupCode?: string, Name?: string, Notes?: string, StatusId?: number, Status?: { Label?: string }, Owner?: { LookupCode?: string, Name?: string }, LicenseplateCount?: { TotalLicenseplates?: number, Lot?: { Material?: { ProjectId?: number } } } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Owners/datasources/ds_projects_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { projectStatusId?: number, fullTextSearch?: string, ownerId?: number, projectIds?: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, LookupCode?: string, Name?: string, Notes?: string, StatusId?: number, Status?: { Label?: string }, Owner?: { LookupCode?: string, Name?: string }, LicenseplateCount?: { TotalLicenseplates?: number, Lot?: { Material?: { ProjectId?: number } } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Owners/datasources/ds_projects_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

