import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class WarehouseTransfers_ds_get_outbound_shipment_licenseplatecontentsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { shipmentId: number }): 
  Promise<{ result: { ShipmentLineId?: number, LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Lot?: { Id?: number, VendorLotId?: number, Material?: { Id?: number, PackagingLookups?: { BasePackagingId?: number, BasePackagingQuantity?: number }[] } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/WarehouseTransfers/datasources/ds_get_outbound_shipment_licenseplatecontents/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { shipmentId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { ShipmentLineId?: number, LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Lot?: { Id?: number, VendorLotId?: number, Material?: { Id?: number, PackagingLookups?: { BasePackagingId?: number, BasePackagingQuantity?: number }[] } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/WarehouseTransfers/datasources/ds_get_outbound_shipment_licenseplatecontents/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { shipmentId: number, $keys: { ShipmentLineId?: number, LicensePlateId?: number, LotId?: number, PackagedId?: number }[] }): 
  Promise<{ result: { ShipmentLineId?: number, LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Lot?: { Id?: number, VendorLotId?: number, Material?: { Id?: number, PackagingLookups?: { BasePackagingId?: number, BasePackagingQuantity?: number }[] } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/WarehouseTransfers/datasources/ds_get_outbound_shipment_licenseplatecontents/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

