import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SalesOrders_ds_get_orders_by_shipmentIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { shipmentId: number }): 
  Promise<{ result: { OrderId?: number, ShipmentId?: number, Order?: { OrderStatusId?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_get_orders_by_shipmentId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { shipmentId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { OrderId?: number, ShipmentId?: number, Order?: { OrderStatusId?: number } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_get_orders_by_shipmentId/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { shipmentId: number, $keys: { OrderId?: number, ShipmentId?: number }[] }): 
  Promise<{ result: { OrderId?: number, ShipmentId?: number, Order?: { OrderStatusId?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_get_orders_by_shipmentId/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

