import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class LoadContainers_ds_load_container_editorService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { loadContainerId?: number }): 
  Promise<{ result: { Id?: number, AvailableDate?: string, CallOutDate?: string, CheckOutDate?: string, ContainerSize?: string, ContainsPortalVisibleAttachments?: boolean, CreatedSysDateTime?: string, CreatedSysUser?: string, InYardDate?: string, LastOnsiteDate?: string, LastPierDate?: string, LookupCode?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, Priority?: number, ReasonCodeId?: number, SealIdentifier?: string, TrailerNumber?: string, Carrier?: { Id?: number, Name?: string }, CarrierServiceType?: { Id?: number, Name?: string }, ContainerType?: { Id?: number, Name?: string }, OrderType?: { Id?: number, Name?: string }, Status?: { Id?: number, Name?: string }, YardLocation?: { Id?: number, Name?: string, WarehouseId?: number }, DockAppointmentsLoadContainersLookup?: { DockAppointmentId?: number, DockAppointment?: { LookupCode?: string, ScheduledLocation?: { Name?: string } } }[] } }> 
  {
    let url = `${environment.backendUrl}api/LoadContainers/datasources/ds_load_container_editor/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

