import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class WorkOrders_ds_get_planned_tasks_by_workorderIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { workOrderId: number }): 
  Promise<{ result: { Id?: number, OperationCode?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.workOrderId)) {
      missingRequiredInParams.push('\'workOrderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/WorkOrders/datasources/ds_get_planned_tasks_by_workorderId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { workOrderId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, OperationCode?: { Name?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.workOrderId)) {
      missingRequiredInParams.push('\'workOrderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/WorkOrders/datasources/ds_get_planned_tasks_by_workorderId/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { workOrderId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, OperationCode?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.workOrderId)) {
      missingRequiredInParams.push('\'workOrderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/WorkOrders/datasources/ds_get_planned_tasks_by_workorderId/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

