import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class UccLabels_ds_ucc_labels_reportService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async get(inParams: { shipment_id?: number, order_id?: number, shipping_container_id?: number, copies?: number }): Promise<{ result?: { Labels?: { ShipmentId?: number, ShippingContainerId?: number, ShippingContainerLookupCode?: string, ShippingContainerLookupCodeFormatted?: string, OrderLookupCode?: string, OrderOwnerReference?: string, OrderVendorReference?: string, OrderNotes?: string, RequestedDeliverDate?: string, FulfillmentDate?: string, CancelThresholdDate?: string, ShipmentLookupcode?: string, BillOfLading?: string, WaveId?: string, CarrierName?: string, CarrierScacCode?: string, OwnerLookupCode?: string, OwnerName?: string, ProjectLookupCode?: string, ProjectName?: string, LicensePlateLookupCode?: string, PackagedAmount?: number, GrossWeight?: number, NetWeight?: number, WeightUom?: string, MaterialLookupCode?: string, MaterialDescription?: string, MaterialUpcCode?: string, ContainerDetails?: string, ContainerType?: string, SerialCounter?: string, ShipFromContactName?: string, ShipFromContactLookup?: string, ShipFromAddressLine1?: string, ShipFromAddressLine2?: string, ShipFromAddressCity?: string, ShipFromAddressState?: string, ShipFromAddressZip?: string, ShipToAccountName?: string, ShipToAccountLookup?: string, ShipToContactName?: string, ShipToContactLookup?: string, ShipToAddressLine1?: string, ShipToAddressLine2?: string, ShipToAddressCity?: string, ShipToAddressState?: string, ShipToAddressZip?: string, BillToAccountName?: string, BillToAccountLookup?: string, BillToContactName?: string, BillToContactLookup?: string, BillToAddressLine1?: string, BillToAddressLine2?: string, BillToAddressCity?: string, BillToAddressState?: string, BillToAddressZip?: string, LotManufactureDate?: string, LotExpirationDate?: string, LotLookupCode?: string, CountryOfOrigin?: string, NetVolume?: number, GrossVolume?: number, VolumeUom?: string, WarehouseName?: string }[] } }> {

    let url = `${environment.backendUrl}api/UccLabels/datasources/ds_ucc_labels_report/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
}

