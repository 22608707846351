import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class LaborManagement_ds_get_tasks_by_taskIdsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { taskIds: number[] }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, Priority?: number, TaskAssignments?: { Id?: number, StatusId?: number, TaskId?: number, UserId?: string }[], Status?: { Name?: string }, ExpectedSourceLocation?: { Name?: string }, OperationCode?: { Name?: string, WorkClassOperationCodes?: { Priority?: number, WorkClass?: { Name?: string } }[] }, Warehouse?: { Id?: number, Name?: string }, Order?: { LookupCode?: string }, Project?: { LookupCode?: string }, Material?: { LookupCode?: string }, Lot?: { LookupCode?: string }, ExpectedTargetLocation?: { Name?: string }, ExpectedPackagedPack?: { Name?: string }, ExpectedTargetShippingContainer?: { LookupCode?: string }, PickSlip?: { Id?: number, Wave?: { Id?: number } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.taskIds)) {
      missingRequiredInParams.push('\'taskIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LaborManagement/datasources/ds_get_tasks_by_taskIds/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { taskIds: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, Priority?: number, TaskAssignments?: { Id?: number, StatusId?: number, TaskId?: number, UserId?: string }[], Status?: { Name?: string }, ExpectedSourceLocation?: { Name?: string }, OperationCode?: { Name?: string, WorkClassOperationCodes?: { Priority?: number, WorkClass?: { Name?: string } }[] }, Warehouse?: { Id?: number, Name?: string }, Order?: { LookupCode?: string }, Project?: { LookupCode?: string }, Material?: { LookupCode?: string }, Lot?: { LookupCode?: string }, ExpectedTargetLocation?: { Name?: string }, ExpectedPackagedPack?: { Name?: string }, ExpectedTargetShippingContainer?: { LookupCode?: string }, PickSlip?: { Id?: number, Wave?: { Id?: number } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.taskIds)) {
      missingRequiredInParams.push('\'taskIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LaborManagement/datasources/ds_get_tasks_by_taskIds/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { taskIds: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, Priority?: number, TaskAssignments?: { Id?: number, StatusId?: number, TaskId?: number, UserId?: string }[], Status?: { Name?: string }, ExpectedSourceLocation?: { Name?: string }, OperationCode?: { Name?: string, WorkClassOperationCodes?: { Priority?: number, WorkClass?: { Name?: string } }[] }, Warehouse?: { Id?: number, Name?: string }, Order?: { LookupCode?: string }, Project?: { LookupCode?: string }, Material?: { LookupCode?: string }, Lot?: { LookupCode?: string }, ExpectedTargetLocation?: { Name?: string }, ExpectedPackagedPack?: { Name?: string }, ExpectedTargetShippingContainer?: { LookupCode?: string }, PickSlip?: { Id?: number, Wave?: { Id?: number } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.taskIds)) {
      missingRequiredInParams.push('\'taskIds\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LaborManagement/datasources/ds_get_tasks_by_taskIds/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

